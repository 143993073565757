import axios from 'axios';
import AuthService from './auth-header';

let API_URL = ''
if (process.env.NODE_ENV === "production") {
    API_URL = 'https://ats.sattvahuman.in/api/';
} else {
    API_URL = 'http://192.168.1.81:5000/api/';
}
// /*dev*/const API_URL = 'http://192.168.1.81:5000/api/';
// /*prod*/ // const API_URL = 'https://ats.sattvahuman.in/api/';
class ContractualConfigService {
    async create(data) {
        console.log(data)
        try {
            const res = await axios.post(API_URL + 'candidate/contractual/config', data,
        { 
            headers: AuthService.authHeader()
        });
        console.log(res.data)
        return res;
        }
        catch (err) {
            console.log(err.response)
            return err.response;
        }
        
    }

    async findAll() {
        try {
            const res = await axios.get(API_URL + 'candidate/contractual/config',
            { 
                headers: AuthService.authHeader()
            });
            console.log(res.data)
            return res
        }
        catch(err) {
            console.log(err.response)
            return err.response
        }
    }

    async findByCandidate(candidate_id, job_id) {
        //console.log(id)
        //console.log(AuthService.authHeader())
        try {
            const res = await axios.get(API_URL + 'candidate/contractual/config/' + candidate_id + '/' + job_id,
            { 
                headers: AuthService.authHeader()
            });
            console.log(res.data)
            return res
        }
        catch(err) {
            console.log(err.response)
            return err.response
        }
    }
    

    async update(candidate_id, job_id, data) {
        try {
            const res = await axios.put(API_URL + 'candidate/contractual//config/' + candidate_id + '/' + job_id, data,
            { 
                headers: AuthService.authHeader()
            });
            console.log(res.data)
            return res
        }
        catch(err) {
            console.log(err.response)
            return err.response
        }
    }

    async delete(id) {
        try {
            const res = await axios.delete(API_URL + 'candidate/contractual/' + id,
            { 
                headers: AuthService.authHeader()
            });
            console.log(res.data)
            return res.data
        }
        catch(err) {
            console.log(err.response)
            return err.response
        }
    }

}

export default new ContractualConfigService();